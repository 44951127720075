// Form validation
var app = angular.module('app', ['ngMessages','ui.mask']);

app.controller('formController', function($scope) {
  $scope.data = {};

  // Set default value
  // $scope.inputFName = 'Константин';
  // $scope.inputLName = 'Ярушковский-Иванов';

  $scope.someSelected = function (object) {
    if (!object) return false;
    return Object.keys(object).some(function (key) {
     	return object[key];
    });
  }

  $scope.doTouched = function() {
     $scope.form.agreement.$setTouched();
  }
});

// Upload file quantity and extension validation
var _validFileExtensions = [".jpg", ".jpeg", ".png"];    

function validateSingleInput(oInput) {
    var sFileName = oInput;

    if (sFileName.length > 0) {
        for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j];
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                return false;
            }
        }
    }

    return true;
}

// Input file validation
app.directive('validFile',function(){
  return {
    require:'ngModel',
    link:function(scope,el,attrs,ngModel){
      
      el.bind('change',function(){
        scope.$apply(function(){

			// Detect free capacity
			var self = $(el),
				patron = self.parents('.upload-group');
			var uploaded_items = patron.find('.upload--success').length;

			if( el[0].files.length > (el[0].dataset.max - uploaded_items) ){
				ngModel.$setValidity('quantity', false);
			} else {
				ngModel.$setValidity('quantity', true);

				for(var i = 0; i < el[0].files.length; i++){
					if( validateSingleInput(el[0].files[i].name) ){
						self.val('');
						ngModel.$setValidity('extension', false);
					} else {
						ngModel.$setValidity('extension', true);
					}
				}
			}

          	ngModel.$setViewValue(el.val());
          	ngModel.$render();
        });
      });
    }
  }
});

// Fields compare validation
app.directive("compareTo", function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {
             
            ngModel.$validators.compareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };
 
            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});

app.directive("moreThan", function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=moreThan"
        },
        link: function(scope, element, attributes, ngModel) {
             
            ngModel.$validators.moreThan = function(modelValue) {
                return (parseInt(modelValue) >= parseInt(scope.otherModelValue));
            };
 
            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});

app.directive('validPlace', function() {
  	return {
	    require : 'ngModel',
	    link : function(scope, element, attrs, ngModel) {
		    ngModel.$parsers.push(function(value) {
		        if(!value || value.length == 0) return;

		        var test,
		        	expression = /\d/;
		        
		        test = expression.test(value);

		        ngModel.$setValidity('number', test);
		        return value;
		    });
	    }
  	};
});

app.directive('validNaming', function() {
  	return {
	    require : 'ngModel',
	    link : function(scope, element, attrs, ngModel) {
		    ngModel.$parsers.push(function(value) {
		        if(!value || value.length == 0) return;

		        var test,
		        	text_type = $(element).data('alpha'),
		        	expression_latin = /[a-zA-Zа-яА-ЯёЁґҐєЄіІїЇ]+/,
		        	expression_cyr = /[а-яА-ЯёЁґҐєЄіІїЇ]+/;
		        
		        if ( text_type == "cyrillic" ){
		        	test = expression_cyr.test(value);
		        } else{
		        	test = expression_latin.test(value);
		        }

		        ngModel.$setValidity('naming', test);
		        return value;
		    });
	    }
  	};
});

$(document).ready(function(){
	// Basic variables
	var display_width = $(window).outerWidth();

	// Media validation
	var display_type,
    	previus_display;

    // Animation time
    	var time_fast = 380,
    		time_slow = 620;

    // Detect current device
    if( display_width >= 1280){
		previus_display = 'desk';
	} else if( display_width >= 993 && display_width < 1280){
		previus_display = 'laptop';
	} else if( display_width >= 768 && display_width < 993){
		previus_display = 'tablet';
	} else if( display_width < 768){
		previus_display = 'mobile';
	}

	// Overlay
	$('.overlay').on('click', function(){
		var elements_array = ['.nav-toggle', '.header-offscreen'];

		for (var i = 0; i < elements_array.length; i++){
			$(elements_array[i])[0].classList.remove('active');
		}

		$(this)[0].classList.remove('active');
	});

	function toggle_overlay(){
		$('.overlay')[0].classList.toggle('active');
	}

	// Navigation
	$('.nav-toggle').on('click', function(e){
		e.preventDefault();

		if( !($(this).hasClass('active')) ) {
			window.removeEventListener("mousewheel", MouseWheelHandler, false); 
			window.removeEventListener("DOMMouseScroll", MouseWheelHandler, false);
		} else {
			window.addEventListener("mousewheel", MouseWheelHandler, false); 
			window.addEventListener("DOMMouseScroll", MouseWheelHandler, false);
		}

		$(this)[0].classList.toggle('active');
		$('.header-offscreen')[0].classList.toggle('active');
		
		$(this).parents('.header').find('.login')[0].classList.toggle('hide');
		$(this).parents('body')[0].classList.toggle('static-position');
		toggle_overlay();
	});


	// window.blockMenuHeaderScroll = false;
	
	// $(window).on('touchstart', function(e){
	//     if ($(e.target).parents('.header').length > 0)
	//     {
	//         blockMenuHeaderScroll = true;
	//     }
	// });

	// $(window).on('touchend', function(){
	//     blockMenuHeaderScroll = false;
	// });

	// $(window).on('touchmove', function(e){
	// 	if( $('body').hasClass('static-position') ){
	// 	    if (blockMenuHeaderScroll)
	// 	    {
	// 	        e.preventDefault();
	// 	    }
	// 	}
	// });

	// Multilevel menu
	$('.menu__control, .menu__claster').on('click', function(e){
		e.preventDefault();

		var menu_level = $(this).data('level');
		$(menu_level)[0].classList.add('active');

		$(this).parents('.menu')[0].classList.add('active');
	});

	$('.menu__return').on('click', function(e){
		e.preventDefault();

		$(this).parent()[0].classList.remove('active');

		$(this).parents('.menu')[0].classList.remove('active');
	});

	// Lang toggle
	$('.lang__item').on('click', function(e){
		e.preventDefault();
		
		var controls = '.lang__item',
			target = $(this),
			toggle = $('.lang__toggle');
			selected_lang = target.data('lang'),
			current_lang = toggle.data('current');

		if( selected_lang != current_lang ){
			toggle.data('current', selected_lang)[0].classList.toggle('change');

			target.siblings(controls).each(function(){
				$(this)[0].classList.remove('active');
			});
			target[0].classList.add('active');
		}
	});

	$('.lang__toggle').on('click', function(){
		var controls = '.lang__item',
			toggle = $(this),
			current_lang = toggle.data('current');
		
		if( current_lang == "ru" ){
			change_lang('urk');
		} else {
			change_lang('ru');
		}

		function change_lang(language_code){
			toggle.data('current', language_code);

			toggle.siblings(controls).each(function(){
				$(this)[0].classList.remove('active');
			});
			$('[data-lang="' + language_code +'"]')[0].classList.add('active');
		}

		toggle[0].classList.toggle('change');
	});

	// Contact info
	$('.contact-info__control').on('click', function(e){
		e.preventDefault();

		$(this)[0].classList.toggle('active');
		$(this).siblings('.contact-info__helper')[0].classList.toggle('active');
	});

	$('.link--call').on('click', function(e){
		if($('.contact-info__control').hasClass('active')){
			$('.contact-info__control').trigger('click');
		}
	});

	// Tooltip
	$('.tooltip-trigger').on('mouseenter', function(){
		var tooltip = $(this).find('.tooltip');

		tooltip[0].classList.add('active');
	}).on('mouseleave', function(){
		$(this).find('.tooltip')[0].classList.remove('active');
	});

	$('.tooltip-trigger').on('click', function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
	});

	// Form
	$('.form').on('submit', function(e){
      	e.preventDefault();
      	var form = $(this);

      	if ( hasClass(this, 'ng-valid') ){
      		if( hasClass(this, 'captcha-validate')){

      			if( hasClass(this, 'form--modal') ){

      				widgetId2 = grecaptcha.render('recaptcha-alt', {
	                	'sitekey' : '6LeiKDAUAAAAAKeKeYASbw3AwSbe2QH7l3pqYRMt',
	                	'callback' : onSubmitAlt,
	                	'size' : "invisible"
	                });

      				grecaptcha.execute(widgetId2);

      			} else {

      				widgetId1 = grecaptcha.render('recaptcha', {
	                	'sitekey' : '6LeiKDAUAAAAAKeKeYASbw3AwSbe2QH7l3pqYRMt',
	                	'callback' : onSubmit,
	                	'size' : "invisible"
	                });
      				grecaptcha.execute(widgetId1);

      			}
   
      		} else {
      			if( hasClass(this, 'form--modal') ){
	      			$(this).parents('.modal-content')[0].classList.add('submit-success');
	      		}

	      		if( hasClass(this, 'form--tabs') ){
	      			$(this).parents('.tab-pane')[0].classList.add('submit-success');
	      		}

	      		var formInput = form.serialize();
	      		$.post(form.attr('action'), formInput);
      		}
      	}

    	form[0].classList.add('ng-dirty');

    	form.find('.form__error').each(function(){
    		$(this)[0].classList.remove('ng-hide');
    	});

    	form.find('.ng-untouched').each(function(){
    		$(this)[0].classList.remove('ng-untouched');
    		$(this)[0].classList.add('ng-touched');
    	});

    	form.find('.form__input, .checkbox').each(function(){
    		$(this)[0].classList.remove('ng-pristine');
    	})

    	form.find('.custom-select-block[class*="required"]').each(function(){
    		if ( hasClass(this, 'ng-pristine') ){
    			this.classList.add('invalided');
    		}
    	}); 	
  	});

  	$('.submit-control').on('click', function(e){
  		e.preventDefault();
  		var form_target = $(this).data('connect');

		$(form_target).submit();  		
  	});

  	$('.btn--addon').on('click', function(e){
  		e.preventDefault(); 		
  	});

  	$('.calc-sidebar').on('click', function(e){
  		e.preventDefault(); 

  		$(this).parents('.calculator__info')[0].classList.remove('active');

  		$('html, body').animate({
	        scrollTop: $( $(this).data('target') ).offset().top - (display_width < 480 ? 30 : -40)
	    }, time_slow);		
  	});

  	$('.info-close').on('click', function(e){
  		e.preventDefault(); 
  		$(this).parents('.calculator__info')[0].classList.remove('active');
  	});

  	$('.link-info-calc').on('mouseenter', function(){
  		if(display_width > 768){
  			$(this).siblings('.tooltip')[0].classList.add('active');
  		}
  	}).on('mouseleave', function(){
  		if(display_width > 768){
  			$(this).siblings('.tooltip')[0].classList.remove('active');
  		}
  	});

  	$('.link-info-calc').on('click', function(e){
  		if(display_width < 768){
  			e.preventDefault();

  			if( $(this).siblings('.tooltip').hasClass('active') ){
				$(this).siblings('.tooltip')[0].classList.remove('active');
			} else {
				var tooltip = $(this).siblings('.tooltip');
				tooltip[0].classList.add('active');

				var bottom_of_element = tooltip.offset().top + tooltip.outerHeight();
				var bottom_of_screen = $(window).scrollTop() + $(window).height();

				if(bottom_of_element > bottom_of_screen){
					$('html, body').animate({
				        scrollTop: $(window).scrollTop() + (bottom_of_element - bottom_of_screen)
				    }, time_slow);
				} 
			}
  		}
  	});

  	$('.edit-form').on('click', function(){
  		$('.edit-close').trigger('click');
  	});

  	$('.edit-calc').on('click', function(e){
  		e.preventDefault();
  		$('.calc-close').trigger('click');
  	});

	$('.form__input').each(function(){
		if( $(this).val().length > 0 ){
			$(this).parent()[0].classList.add('passed');
		}
	});

	$('.form__input').on('focus', function(){
		$(this).parent()[0].classList.add('focused');
	}).on('blur', function(){
		var self = $(this);
		self.parent()[0].classList.remove('focused');

		if( self.val().length > 0 && !hasClass(this, 'ng-invalid')){
			self.parent()[0].classList.add('passed');

			if( self.hasClass('input-change') ){
				if(self.attr('type') == 'tel' ){
					self.parents('.form')[0].classList.add('phone-changed');
				} else {
					self.parents('.form')[0].classList.add('email-changed');
				}
			}
		} else {
			self.parent()[0].classList.remove('passed');

			if( self.hasClass('input-change') ){
				if(self.attr('type') == 'tel' ){
					self.parents('.form')[0].classList.remove('phone-changed');
				} else {
					self.parents('.form')[0].classList.remove('email-changed');
				}
			}
		}
	});

	$('.input-capitalize').on('input', function(event) {
        var self = $(this)
        	input_value = self.val();

        var words = input_value.split(' '),
        	result = [];;

        for ( var i = 0; i < words.length; i++){
        	result[i] = words[i].charAt(0).toUpperCase() + words[i].substr(1);
        }

        self.val(result.join(' '));
    });

    $('.input-uppercase').on('input', function(event) {
    	var self = $(this);

    	self.val(self.val().toUpperCase());
    	self.trigger('change');
    });

    $('.input-separate').on('input', function(event) {
    	var self = $(this),
    		input_value = self.val();

    		clean_value = input_value.split('-').join('');
    		if (clean_value.length > 8){
    			self.val(clean_value.substr(0,8) + "-" + clean_value.substr(8));
    		}
    });

    $('.input-latin').on('input', function(e) {
    	var self = $(this),
    		input_value = self.val(),
    		current_value = input_value.slice(-1),
    		test_cyrillic = /[а-яА-ЯёЁґҐєЄіІїЇ]/;
    	
    	if (test_cyrillic.test(current_value)){
    		self.val(input_value.slice(0, -1));
    		$(this).trigger('change');
    	}
    });

    $('.input-cyrillic').on('input', function(e) {
    	var self = $(this),
    		input_value = self.val(),
    		current_value = input_value.slice(-1),
    		test_cyrillic = /[а-яА-ЯёЁґҐєЄіІїЇ]/;
    	
    	if (!test_cyrillic.test(current_value)){
    		self.val(input_value.slice(0, -1));
    		$(this).trigger('change');
    	}
    });

    $('.limit-words').on('input', function(e) {
    	var self = $(this),
    		input_value = self.val(),
    		words = input_value.split(' ').length;

    		if (words > 3){
    			self.val(input_value.slice(0, -1));
    			$(this).trigger('change');
    		}
    });

    $('.personal-income').on('input', function(event) {
    	var related = $(this).data('sum');
    	$(related).change();
    });

    $('[ng-model="inputPassword"]').on('input', function(event) {
    	var self = $(this),
    		related = $('[ng-model="inputRePassword"]');
    	
    	if( self.val() == related.val() ){
    		$('[ng-model="inputRePassword"]').blur();
    	}
    });

	$('.form__toggle').on('click', function(e){
		e.preventDefault();
		var pass_toggle = $(this);

		if( hasClass(this, 'active') ){
			pass_toggle.siblings('.form__input').attr('type', 'password');
		} else {
			pass_toggle.siblings('.form__input').attr('type', 'text');
		}
	
		pass_toggle[0].classList.toggle('active');
	});

	$('.upload-group').each(function(){
		var current_uploads = $(this).find('.upload-preview').length;
			
		if(current_uploads > 0){
			$(this).find('.filecheck').val('exist');
           	$(this).find('.filecheck').trigger('change');
		}
	});

	$('.select-search').jsCustomSelect({
		search: true,
		searchNoResults: 'Нет совпадений',
		searchInputHeight: 58,
		'max-height': 255,
		placeholder: '',
        scroll: {
            minScrollbarLength: 40
        }
	});

	$('.select-list').jsCustomSelect({
		search: false,
		placeholder: '',
        scroll: {
            minScrollbarLength: 40
        },
        change:function(e){
        	$(e).parents('.select-group').find('.select-list-item').each(function(){
        		$(this)[0].classList.remove('selected');

        		if ( $(this).data('value') == e.value ){
        			$(this)[0].classList.add('selected');
        		}
        	});
        }
	});

	$('.select-choose').jsCustomSelect({
		search: false,
		'max-height': 250,
		placeholder: '',
        scroll: {
            minScrollbarLength: 40
        },
        change: function(e){
        	$(e).parents('.card')[0].classList.add('card--change');

        	$(e).parents('.select-group').find('.select-list-item').each(function(){
        		$(this)[0].classList.remove('selected');

        		if ( $(this).data('value') == e.value ){
        			$(this)[0].classList.add('selected');
        		}
        	});
        }
	});

	$('.value-field').on('click', function(){
		var self = $(this),
			parent = self.parents('.select'),
			dropdown = parent.find('.custom-select-list');

		if(parent.hasClass('open')){
			var bottom_of_element = dropdown.offset().top + dropdown.outerHeight();
    		var bottom_of_screen = $(window).scrollTop() + $(window).height();

    		if(bottom_of_element > bottom_of_screen){
    			$('html, body').animate({
			        scrollTop: $(window).scrollTop() + (bottom_of_element - bottom_of_screen)
			    }, time_slow);
    		}
		}
	});

	$('.select-label').on('click', function(e){
		e.preventDefault();

		$(this).siblings('.select').find('.value-field').trigger('click');
	});

	$('.card-confirm').on('click', function(e){
		e.preventDefault();

		$(this).parents('.card')[0].classList.remove('card--change');
	});

	$('select').on('change', function(){
		$(this).parent()[0].classList.add('selected');

		$(this).siblings('.custom-select-block')[0].classList.remove('invalided', 'ng-pristine', 'ng-untouched', 'ng-empty', 'ng-invalid');
	});

	$('select').on('focus', function(){
		$(this).on('keydown', function(e){
			if ( (e.keyCode >= 35 && e.keyCode <= 40) ) {
	            e.preventDefault();
	        }
		});
	});

	if ( $('.licence').length > 0 ){
		$('.licence').each(function(){
			var new_id = $(this).attr('id');
			
			new jsCustomScroll(document.getElementById(new_id));
		});
	}

	if ( $('#notification-box').length > 0 ){
		new jsCustomScroll(document.getElementById('notification-box'));
	}

	$('.user__box').on('click', function(e){
		e.preventDefault();

		// Scroll top
		$('html, body').animate({
	        scrollTop: $(this).offset().top - 100
	    }, time_slow);
		$(this).parent()[0].classList.toggle('active');
	});

	$('.notification').on('click', function(e){
		e.preventDefault();
	});

	$('.notification-more').on('click touchstart', function(e){
		e.preventDefault();
		
		$(this)[0].classList.toggle('active');
		$(this).siblings('.notification-full').slideToggle(time_fast);
	});

	// new jsCustomScroll(document.getElementById('licence'));

	
	$('.repeat-group .link--info').on('click', function(e){
		e.preventDefault();
		var self = $(this),
			parent = self.parent(),
			timer = parent.find('.tiny-timer');

		var time_left = timer.find('.seconds').html();

		if ( !time_left || time_left === 0 ){

			parent[0].classList.add('active');

			var minute = addSeconds(59);
			initializeClock(timer, minute);
		
		}

		setTimeout(function(){
			parent[0].classList.remove('active');
		}, 60000)
	});

	$('.btn-decline').on('click', function(e){
		e.preventDefault();

		setTimeout(function(){
			$('#modal-decline').modal('show');
		}, 500); 
	});

	// Range slider
	var slider_money = document.getElementById('slider-money'),
		input_money = document.getElementById('input-money');

	if ( slider_money ){
		var min_value = slider_money.getAttribute('data-min') == 0 ? 0 : parseInt(slider_money.getAttribute('data-min')) || 500,
			max_value = parseInt(slider_money.getAttribute('data-max')) || 3000;

		range_slider(slider_money, input_money, {
			start: slider_money.getAttribute('data-value') || 500,
			step: parseInt(slider_money.getAttribute('data-step')) || 50,
			connect: [true, false],
			range: {
				'min': min_value,
				'max': max_value
			}
		});
	}

	var slider_time = document.getElementById('slider-time'),
		input_time = document.getElementById('input-time');

	if ( slider_time) {
		var min_value = slider_time.getAttribute('data-min') == 0 ? 0 : parseInt(slider_time.getAttribute('data-min')) || 1,
			max_value = parseInt(slider_time.getAttribute('data-max')) || 20;

		range_slider(slider_time, input_time, {
			start: slider_time.getAttribute('data-value') || 20,
			step: 1,
			connect: [true, false],
			range: {
				'min': 1,
				'max': 20
			}
		});

		slider_time.noUiSlider.on('update', function( values, handle ) {
			var slider__label = $('.value--time'),
				current_item = parseInt(values[handle]),
				label_variants = ['день', 'дня', 'дней'];

			slider__label[0].classList.remove('dimension--sm');

			if( current_item == 1 ){
				slider__label.next().html(label_variants[0]);
			} else if(current_item == 2 || current_item == 3 || current_item == 4) {
				slider__label.next().html(label_variants[1]);
				slider__label[0].classList.add('dimension--sm');
			} else {
				slider__label.next().html(label_variants[2]);
			}
		});
	}

	function range_slider(id, input, config){
		noUiSlider.create(id, config);

		id.noUiSlider.on('update', function( values, handle ) {
			var number = parseInt(values[handle]);			
			input.value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		});

		input.addEventListener('change', function(){
			this.value = this.value.replace(/ /g,'');
    		id.noUiSlider.set(this.value);
		});
	}


	$('.number-field').on('keydown', function(e){
		// Allow: backspace, delete, tab, escape, enter
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
             // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) || 
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
        }

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
	});

	$('.limit-field').on('keydown', function(e){
		var limit = $(this).attr('ng-maxlength'),
			value_symbols = $(this).val().length;

			function getSelectedText() {
			    var text = "";
			    if (typeof window.getSelection != "undefined") {
			        text = window.getSelection().toString();
			    } else if (typeof document.selection != "undefined" && document.selection.type == "Text") {
			        text = document.selection.createRange().text;
			    }
			    return text;
			}
			var selectedText = getSelectedText();

			if ( limit <= value_symbols && selectedText.length < 1) {

				// Allow: backspace, delete, tab, escape, enter
				if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
	             	// Allow: Ctrl+A, Command+A
	            	(e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) || 
	            	// Allow: home, end, left, right, down, up
	            	(e.keyCode >= 35 && e.keyCode <= 40)) {
	               		return;
		        } else {
		        	e.preventDefault();
		        }
        	
        	}
	});

	$('.calculator__info-toggle').on('click', function(e){
		e.preventDefault();
		var info_block = $(this).parent()
		info_block[0].classList.add('active');

		if( display_width < 768 ){
			setTimeout(function(){
				var bottom_of_element = info_block.offset().top + info_block.outerHeight();
				var bottom_of_screen = $(window).scrollTop() + $(window).height();

				if(bottom_of_element > bottom_of_screen){
					$('html, body').animate({
				        scrollTop: $(window).scrollTop() + (bottom_of_element - bottom_of_screen)
				    }, time_slow);
				} 
			}, time_slow)
		}
	});

	$('.tabs-view a').on('click', function(e){
		var current_situation = $(this).data('view');

		if (current_situation == 'login'){
			$('body')[0].classList.add('clean-view');
		} else {
			$('body')[0].classList.remove('clean-view');
		}
	});

	$('.accordion a, .faq-accordion a').on('click', function(e){
		var self = $(this);

		setTimeout(function(){
			$('html, body').animate({
		        scrollTop: self.offset().top - (display_width < 480 ? 70 : 0)
		    }, time_slow);
		}, 350);
	});

	if( $('content--faq').length > 0 ){
		activator(obj);
	}

	// Toggle calc config
	$('.calculator-config').on('click', function(e){
		e.preventDefault();
		var calc = $(this).parents('.calculator');

		calc[0].classList.add('active');

		calc.find('.calculator__preivew-header').slideUp(time_fast);
		calc.find('.calculator__top').delay(time_fast).slideDown(time_slow);
		calc.find('.calculator__submit').delay(time_fast + time_slow).slideDown(time_fast);
	});

	$('.calculator__submit > .btn').on('click', function(e){
		e.preventDefault();
		var calc = $(this).parents('.calculator');

		calc[0].classList.remove('active');

		calc.find('.calculator__submit').slideUp(time_fast);
		calc.find('.calculator__top').delay(time_fast).slideUp(time_slow);
		calc.find('.calculator__preivew-header').delay(time_fast + time_slow).slideDown(time_fast);
	});

	// Edit page interaction
	$('.calc-open').on('click', function(e){
		e.preventDefault();
		var toggle = $(this),
			parent = toggle.parents('.toogle-section');

			toggle[0].classList.add('insivible')
			parent.find('.credit')[0].classList.add('credit--open');

			parent.find('.calculator').slideDown(time_slow);
			parent.find('.change-controls').delay(time_slow).slideDown(time_fast);

			// Hide next
			$('.next-block').slideUp(time_fast);
	});

	$('.calc-close').on('click', function(e){
		e.preventDefault();
		var toggle = $(this),
			parent = toggle.parents('.toogle-section'),
			alt = parent.find('.calc-open');

			alt[0].classList.remove('insivible')
			parent.find('.credit')[0].classList.remove('credit--open');

			parent.find('.change-controls').slideUp(time_fast);
			parent.find('.calculator').delay(time_fast).slideUp(time_slow);

			$('html, body').animate({
		        scrollTop: parent.offset().top - (display_width < 480 ? 70 : 0)
		    }, time_slow);

			// Hide next
			$('.next-block').slideDown(time_fast);
	});

	// Edit form interaction
	$('.edit-open').on('click', function(e){
		e.preventDefault();
		var toggle = $(this),
			parent = toggle.parents('.toogle-section');

			toggle[0].classList.add('insivible')
			parent.find('.toggle-first').slideUp(time_slow);
			parent.find('.toggle-second').delay(time_slow).slideDown(time_slow);

			// Hide next
			$('.next-block').slideUp(time_fast);
	});

	$('.edit-close').on('click', function(e){
		e.preventDefault();
		var toggle = $(this),
			parent = toggle.parents('.toogle-section'),
			alt = parent.find('.edit-open');

			alt[0].classList.remove('insivible')
			parent.find('.toggle-second').slideUp(time_slow);
			parent.find('.toggle-first').delay(time_slow).slideDown(time_slow);

			// Scroll top
			$('html, body').animate({
		        scrollTop: parent.offset().top - (display_width < 480 ? 70 : 0)
		    }, time_slow);
			
			// Show next
			$('.next-block').slideDown(time_fast);
	});

	// Interactive section change
	$('.toggle-next').on('click', function(e){
		e.preventDefault();

		var toggle = $(this),
			current_section = toggle.data('current'),
			target_section = toggle.data('target')
			parent = toggle.parents('.toogle-section');

			parent.find(current_section).slideUp(time_slow);
			parent.find(target_section).delay(time_slow).slideDown(time_slow);
	});

	$('.config-top-hide').on('click', function(e){
		$('.config-top').slideUp(time_slow);
	});

	$('.config-top-show').on('click', function(e){
		$('.config-top').slideDown(time_slow)
	});
	

	$('.pass-change').on('click', function(e){
		e.preventDefault();

		$(this)[0].classList.add('insivible');
		$('.password-form').slideDown(time_slow);
	});

	$('.pass-back').on('click', function(e){
		e.preventDefault();

		$('.pass-change')[0].classList.remove('insivible');
		$('.password-form').slideUp(time_slow);
	});
	

	$('#passport_id').change(function() {

        if($(this).is(":checked")) {
        	$('.passport-regular').slideUp(time_slow);
            $('.passport-id').delay(time_slow).slideDown(time_slow);
        } else {
        	$('.passport-id').slideUp(time_slow);
            $('.passport-regular').delay(time_slow).slideDown(time_slow);
        }    

    })

	$('.detail__control').on('click', function(e){
		e.preventDefault();
		var self = $(this);
			current_state = self.html(),
			open_state = self.data('open'),
			close_state = self.data('close');

		if( current_state == open_state ){
			self.html(close_state);
			self[0].classList.remove('active');
			self.siblings('.detail--more').slideUp(time_fast);
		} else {
			self.html(open_state);
			self[0].classList.add('active');
			self.siblings('.detail--more').slideDown(time_fast);
		}
	});

	$('.promo-code__toggle').on('click', function(e){
		e.preventDefault();
		var self = $(this);

		if ( self.parents('.promo-code').hasClass('promo-code--single') ){
			self.parents('.promo-code').siblings('.btn-group')[0].classList.add('group--shrink');
		}

		self.slideToggle(time_fast)
		self.siblings('.promo-code__form').delay(time_fast).slideToggle(time_fast);
	});

	$('.promo-code__close').on('click', function(e){
		e.preventDefault();
		var self = $(this);

		if ( self.parents('.promo-code').hasClass('promo-code--single') ){
			setTimeout(function(){
				self.parents('.promo-code').siblings('.btn-group')[0].classList.remove('group--shrink');
			}, time_fast); 	
		}

		self.parents('.promo-code__form').slideToggle(time_fast)
		self.parents('.promo-code').find('.promo-code__toggle').delay(time_fast).slideToggle(time_fast);
	});

    $('.sequence-toggle').on('click', function(e){
		e.preventDefault();

		$(this)[0].classList.toggle('active');
		$(this).next().slideToggle(time_slow);
	});

	$('.document-toggle').on('click', function(e){
		e.preventDefault();
		var self = $(this),
			text_block = self.find('.toggle-text'),
			current_state = text_block.html(),
			open_state = self.data('open'),
			close_state = self.data('close'),
			parent_block = self.parents('.document');

			if (current_state == open_state){
				text_block.html(close_state);
				parent_block[0].classList.add('active');
				parent_block.find('.document__preview').slideDown(time_slow);

				setTimeout(function(){
					$('html, body').animate({
				        scrollTop: parent_block.offset().top - (display_width < 480 ? 70 : 0)
				    }, time_slow);
				}, time_slow);
				
			} else {
				text_block.html(open_state);
				parent_block[0].classList.remove('active');
				parent_block.find('.document__preview').slideUp(time_slow);
			}

	});

	$('.sidebar').theiaStickySidebar({
	  containerSelector: '.main-content',
      additionalMarginTop: 20,
      additionalMarginBottom: 20
    });

	$('.login-target').on('click', function(e){
		e.preventDefault();

		$(this)[0].classList.toggle('active');
		$(this).siblings('.login').slideToggle(time_slow);
	});

	$('.nav-sidebar-target').on('click', function(e){
		e.preventDefault();

		$(this)[0].classList.toggle('active');
		$(this).siblings('.nav-sidebar').slideToggle(time_slow);
	});

	$('.faq-sidebar .nav-sidebar__item').on('click', function(e){
		e.preventDefault();
		var self = $(this),
			parent = self.parents('.faq-sidebar');

		if( display_width < 993 ){
			$('.nav-sidebar-target').trigger('click');

			setTimeout(function(){
				$('html, body').animate({
			        scrollTop: $(self.data('faq')).offset().top - (display_width < 480 ? 130 : 60)
			    }, time_slow);
			}, time_slow);
		} else {
			$('html, body').animate({
		        scrollTop: $(self.data('faq')).offset().top - 60
		    }, time_slow);
		}

		parent.find('.nav-sidebar__item').each(function(){
			$(this)[0].classList.remove('active');
		});

		self[0].classList.add('active');
	});
	
	
	$('.link-fake').on('click', function(e){
		e.preventDefault();
		var target_link = $(this).data('target');

		// window.location.replace(target_link);
		window.open(target_link, '_blank');
	});

	$('.link-more').on('click', function(e){
		e.preventDefault();
		var self = $(this);
			current_state = self.html(),
			open_state = self.data('open'),
			close_state = self.data('close');

		if( current_state == open_state ){
			self.html(close_state);
			self[0].classList.add('active');
			self.siblings('.seo-full').slideDown(time_fast);
		} else {
			self.html(open_state);
			self[0].classList.remove('active');
			self.siblings('.seo-full').slideUp(time_fast);

			$('html, body').animate({
	        	scrollTop: self.parent().offset().top - 120
	   		}, time_slow);
		}
	});

	// Sliders
	var pricing_slider = new Swiper('.pricing-group', {
        slidesPerView: 3,
        pagination: '.pagination-pricing',
        paginationClickable: true,
        simulateTouch: false,
        breakpoints: {
		    560: {
		     	slidesPerView: 1,
		    },
		    768: {
		      	slidesPerView: 2,
		    },
		    993: {
		      slidesPerView: 3,
		    },
		    1279: {
		      slidesPerView: 2,
		    }
		}
    });

	var pricing_slider_full = new Swiper('.pricing-row', {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: '.pagination-pricing',
        paginationClickable: true,
        loop: true,
        breakpoints: {
		    560: {
		     	slidesPerView: 1,
		    },
		    768: {
		      	slidesPerView: 2,
		    },
		    993: {
		      slidesPerView: 3,
		    }
		}
    });

	// var hero_slider = new Swiper('.hero-slider', {
   //      slidesPerView: 1,
   //      pagination: '.pagination-hero',
   //      paginationClickable: true,
   //      autoplay: 5000,
   //      speed: 840,
   //      loop: true,
   //      simulateTouch: false
   //  });

    var feature_slider = new Swiper('.feature-slider', {
        slidesPerView: 1,
        autoplay: 5000,
        loop: true,
        onSlideChangeStart: function () {
        	if(feature_slider){
        		$('.tab-pagination .tab__item').each(function(){
			    	$(this)[0].classList.remove('active');
			    });
		    	$('[data-reference="' + (feature_slider.realIndex + 1) + '"]').parent()[0].classList.add('active');
        	}
		}
    });
    
    $('.tab-pagination .tab__link').on('click', function(e){
    	e.preventDefault();

    	var reference = $(this).data('reference');
    	feature_slider.slideTo(reference);

    	$('.tab-pagination .tab__item').each(function(){
	    	$(this)[0].classList.remove('active');
	    });
    	$(this).parent()[0].classList.add('active');
    });

	var mobile_feature = new Swiper('.mobile-feature', {
        slidesPerView: 2,
        pagination: '.pagination-feature',
        paginationClickable: true,
        loop: true,
        breakpoints: {
		    560: {
		     	slidesPerView: 1,
		    }
		}
    });

	// Global click detection
	$(document).on('click', function(e){
		// Select search autofocus
		if( $(e.target).parents('.select-search').length > 0 ){
			var self = $(e.target),
				search__input = self.parents('.select-search').find('.search-input');
			search__input.focus();
		}

		if( !$(e.target).parents('.info-calc-holder').length > 0 && $('.tooltip-single').length > 0){
			$('.tooltip-single')[0].classList.remove('active');
		}

		if( !$(e.target).parents('.user__contols').length > 0 && $('.user__contols').length > 0){
			$('.user__contols')[0].classList.remove('active');
		}

		if( !$(e.target).parents('.contact-info--line').length > 0){
			if($('.contact-info__control').hasClass('active')){
				$('.contact-info__control').trigger('click');
			}
		}

		if( !$(e.target).parents('.login').length > 0){
			if( $('.login').hasClass('active') ){
				$('.login')[0].classList.remove('active');
			}
		}
	});

	$('.tooltip-trigger').on('touchstart', function(e){
		if( $(this).find('.tooltip').hasClass('active') ){
			$(this).find('.tooltip')[0].classList.remove('active');
		} else {
			var tooltip = $(this).find('.tooltip');
			tooltip[0].classList.add('active');

			var bottom_of_element = tooltip.offset().top + tooltip.outerHeight();
			var bottom_of_screen = $(window).scrollTop() + $(window).height();

			var top_of_element = tooltip.offset().top;
			var top_of_screen = $(window).scrollTop();

			if(bottom_of_element > bottom_of_screen){
				$('html, body').animate({
			        scrollTop: $(window).scrollTop() + (bottom_of_element - bottom_of_screen)
			    }, time_slow);
			} 

			if(top_of_element < top_of_screen){
				$('html, body').animate({
			        scrollTop: $(window).scrollTop() - tooltip.outerHeight()
			    }, time_slow);
			}
		}
	});

	$('.login__toggle').on('click', function(e){
		e.preventDefault();
		var self = $(this);

		if ( display_width < 993 ){
			$(this).parent()[0].classList.toggle('active');
		}
	});

	$(document).on('touchstart', function(e){
		if( !$(e.target).parents('.tooltip-trigger').length > 0 && $('.tooltip').length > 0){
			$('.tooltip').each(function(){
				$(this)[0].classList.remove('active');
			});
		}

		if( !$(e.target).parents('.user__contols').length > 0 && $('.user__contols').length > 0){
			$('.user__contols')[0].classList.remove('active');
		}

		if( !$(e.target).parents('.contact-info--line').length > 0){
			if($('.contact-info__control').hasClass('active')){
				$('.contact-info__control').trigger('click');
			}
		}

		if( !$(e.target).parents('.login').length > 0){
			if( $('.login').hasClass('active') ){
				$('.login')[0].classList.remove('active');
			}
		}
	});

	$(document).on('keypress', function(e) {
	    if ( e.keyCode === 13 ){
        	if ( $('.next-step').length > 0 ){
        		if ( $('.next-step').attr('type') == 'submit' ){
        			$('.next-step').trigger('click');
        		} else {
        			window.location.replace($('.next-step').attr('href'));
        		}
        		
        	}
        }
	});

	// Select tab behavior
	$(document).on('keydown', function(e) {
	    if ( e.keyCode === 9 ){
	    	setTimeout(function(){
	    		var $focused = $(':focus'),
	    			$target = $focused.siblings('.custom-select-block');

	    		$('.custom-select-block.open').find('.value-field').trigger('click');
	    		if( $target.length > 0 ){
	    			$target.find('.value-field').trigger('click');	
	    		}

	    	}, 120);
        }
	});

	// Onload rules
	if ( display_width < 993 ){
		$('.lang').appendTo('.lang-mobile');

		$('.fast-calc').appendTo('.mobile-calc');

		$('.seo-transition__content').prependTo('.seo-full');
	}

	$('.modal-resp').on('click', function(e){
		if ( display_width < 768 ){
			e.stopImmediatePropagation();
		}
	});

	$('.detail__control').on('click', function(e){
		if ( display_width < 768 ){
			var self = $(this);

			setTimeout(function(){
				$('html, body').animate({
			        scrollTop: self.parent().offset().top - (display_width < 480 ? 70 : 0)
			    }, time_slow);
			}, time_slow);
		}
	});

	$('.pass-change').on('click', function(e){
		if ( display_width < 768 ){
			var self = $(this);

			setTimeout(function(){
				$('html, body').animate({
			        scrollTop: self.siblings('.password-form').offset().top - (display_width < 480 ? 50 : -20)
			    }, time_slow);
			}, time_slow);
		}
	});

	$('.calculator-config, .calculator__submit .btn').on('click', function(e){
		if ( display_width < 993 ){
			var self = $(this);

			setTimeout(function(){
				$('html, body').animate({
			        scrollTop: self.parents('.calculator').offset().top - 66
			    }, time_slow);
			}, time_slow + time_fast);
		}

	});

	$('.number-field').on('touchstart', function() {
		var number_value = $(this).val().replace(/ /g,'');
		$(this).val(number_value);

	  	$(this).attr('type', 'number');
	});

	$('.number-field + .form__label').on('touchstart', function() {
	  $(this).siblings('.number-field').attr('type', 'number');
	});

	$('.number-field').on('keydown blur', function() {
	  $(this).attr('type', 'text');
	});

	// Scroll events
	// Go top link
	// $(".top-scroll").hide();
  //
	// $('.top-scroll').click(function (e) {
	// 	e.preventDefault();
	//     $('body,html').animate({
	//       scrollTop: 0
	//     }, 800);
	//     return false;
	// });


	var timer,
		last_scroll_value = 0;

	$(window).scroll(function(event){
		if ( timer ) clearTimeout(timer);
		var scroll_value = $(this).scrollTop();

		timer = setTimeout(function(){
			
			// Scroll top link
		    if (scroll_value > 200) {
		      $('.top-scroll').fadeIn(500);
		    } else {
		      $('.top-scroll').fadeOut(500);
		    }
		   	last_scroll_value = scroll_value;
	   	}, 30);   
	});


	$(window).on('resize', function(){
    	display_width = $(window).outerWidth();

    	// Detect new display type
    	if( display_width >= 1280){
    		display_type = 'desk';
    	} else if( display_width >= 993 && display_width < 1280){
    		display_type = 'laptop';
    	} else if( display_width >= 768 && display_width < 993){
    		display_type = 'tablet';
    	} else if( display_width < 768){
    		display_type = 'mobile';
    	}

    	// Resposive behaiour
    	if (previus_display != display_type) {
    		if( display_type == 'tablet' ){
    			$('.lang').appendTo('.lang-mobile');

    			$('.fast-calc').appendTo('.mobile-calc');

    			$('.seo-transition__content').prependTo('.seo-full');
    		} else if ( display_type == 'laptop' ) {
    			$('.lang').appendTo('.lang-desktop');

    			$('.fast-calc').appendTo('.desktop-calc');

    			if( $('.nav-toggle').hasClass('active') ){
    				$('.nav-toggle').trigger('click');
    			}

    			$('.seo-transition__content').prependTo('.seo-transition');
    		}
		}

		if (previus_display != display_type) {
    		if( display_type == 'mobile' ){
    			if( $('.tab-pane-load').length > 0 && display_width < 768 ){
					$('.tab-pane-load').each(function(){
						$(this)[0].classList.remove('tab-pane-load');
					})
				}

				if( $('.collapse-load').length > 0 && display_width < 768 ){
					$('.collapse-load').each(function(){
						$(this)[0].classList.remove('collapse-load')
					})
				}
    		}
    	}

		// Set up compare value
		return previus_display = display_type;
    });

    // Init countdown
	$('.timer').each(function() {
		var endtime,
			$this = $(this); 

		if( $this.hasClass('timer--short')){
			endtime = addMinutes(5);
		} else{
			endtime = addMinutes(15);
		}
		
		initializeClock($this, endtime);
	});

	$(window).on('load', function(){
		if($('#document-page').length > 0){
			var url = window.location.href.split("#");
			$("#" + url[1]).find('.document-toggle').trigger('click');
		}

		if( $('.tab-pane-load').length > 0 && display_width < 768 ){
			$('.tab-pane-load').each(function(){
				$(this)[0].classList.remove('tab-pane-load')
			})
		}

		if( $('.collapse-load').length > 0 && display_width < 768 ){
			$('.collapse-load').each(function(){
				$(this)[0].classList.remove('collapse-load')
			})
		}

		if($('.sidebar').length > 0 && $('.box').length > 0){
			var sidebar = $('.sidebar').height(),
				box = $('.box').height();

			if ( sidebar > box ) {
				$('.box').css('min-height', sidebar);
			}
		}

		$('.select .search-input').on('input', function(event) {
	    	var self = $(this)
	        	input_value = self.val();

	        var words = input_value.split(' '),
	        	result = [];

	        for ( var i = 0; i < words.length; i++){
	        	result[i] = words[i].charAt(0).toUpperCase() + words[i].substr(1);
	        }

	        self.val(result.join(' '));
	    });
		
	});
});

// Recaptcha submision
function onSubmit(token){
	var form = document.getElementById("form-question");

	if( hasClass(form, 'form--tabs') ){
		$(formv).parents('.tab-pane')[0].classList.add('submit-success');
	}

	// form.submit();
}

function onSubmitAlt(token){
	var form = document.getElementById("form-question-alt");

	if( hasClass(form, 'form--modal') ){
		$(form).parents('.modal-content')[0].classList.add('submit-success');
	}

	// form.submit();
}

// Countdown
function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(clock_class, endtime) {
  var clock = $(clock_class),
  	  daysSpan = clock.find('.days'),
  	  hoursSpan = clock.find('.hours'),
  	  minutesSpan = clock.find('.minutes'),
  	  secondsSpan = clock.find('.seconds');

  function updateClock() {
  	var t = getTimeRemaining(endtime);

  	daysSpan.html( t.days );
	hoursSpan.html( ('0' + t.hours).slice(-2) );
	minutesSpan.html( ('0' + t.minutes).slice(-2) );
	secondsSpan.html( ('0' + t.seconds).slice(-2) ); 
    
    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

var obj = [{
    $item: $('#question-all'),
    $menuItem: $("a[data-faq='#question-all']"),
}, {
    $item: $('#question-general'),
    $menuItem: $("a[data-faq='#question-general']"),
}, {
    $item: $('#question-get'),
    $menuItem: $("a[data-faq='#question-get']"),
}, {
    $item: $('#question-pay'),
    $menuItem: $("a[data-faq='#question-pay']"),
}, {
    $item: $('#question-exist'),
    $menuItem: $("a[data-faq='#question-exist']"),
}, {
    $item: $('#question-decline'),
    $menuItem: $("a[data-faq='#question-decline']"),
}, {
    $item: $('#question-delay'),
    $menuItem: $("a[data-faq='#question-delay']"),
}, {
    $item: $('#question-lk'),
    $menuItem: $("a[data-faq='#question-lk']"),
}, {
    $item: $('#question-bank'),
    $menuItem: $("a[data-faq='#question-bank']"),
}]

function activator(obj) {
    var index = -1, timer;
    $(window).scroll(function(e) {
    	if ( timer ) clearTimeout(timer);

    	timer = setTimeout(function(){
	        var top = $(this).scrollTop();
	        for (var i = 0; i < obj.length; i++) {
	            var itemToTop = obj[i].$item.offset().top - 100;
	            if (top > itemToTop) {
	                index = i;
	            }
	        };

	        if (index != -1) {
	            for (var i = 0; i < obj.length; i++) {
	                obj[i].$menuItem.removeClass('active')
	            }
	            obj[index].$menuItem.addClass('active');
	        }
    	}, 20);
    });
}

// Helpers function
function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

// Set time
function addMinutes(minutes) {
    return new Date(Date.now() + minutes*60000);
}

function addSeconds(seconds) {
    return new Date(Date.now() + seconds*1000);
}

// Page smooth scroll
Math.easeOutQuad = function (t, b, c, d) { t /= d; return -c * t*(t-2) + b; };

var
  interval, // scroll is being eased
  mult = 0, // how fast do we scroll
  dir = 0, // 1 = scroll down, -1 = scroll up
  steps = 70, // how many steps in animation
  length = 40; // how long to animate
function MouseWheelHandler(e) {
  e.preventDefault(); // prevent default browser scroll
  clearInterval(interval); // cancel previous animation
  ++mult; // we are going to scroll faster
  var delta = -Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail))); // cross-browser
  if(dir!=delta) { // scroll direction changed
    mult = 1; // start slowly
    dir = delta;
  }
  // in this cycle, we determine which element to scroll
  for(var tgt=e.target; tgt!=document.documentElement; tgt=tgt.parentNode) {
    var oldScroll = tgt.scrollTop;
    tgt.scrollTop+= delta;
    if(oldScroll!=tgt.scrollTop) break;
    // else the element can't be scrolled, try its parent in next iteration
  }
  var start = tgt.scrollTop;
  var end = start + length*mult*delta; // where to end the scroll
  var change = end - start; // base change in one step
  var step = 0; // current step
  interval = setInterval(function() {
    var pos = Math.easeOutQuad(step++,start,change,steps); // calculate next step
    tgt.scrollTop = pos; // scroll the target to next step
    if(step>=steps) { // scroll finished without speed up - stop animation
      mult = 0; // next scroll will start slowly
      clearInterval(interval);
    }
  },10);
}

// nonstandard: Chrome, IE, Opera, Safari
window.addEventListener("mousewheel", MouseWheelHandler, false); 
// nonstandard: Firefox
window.addEventListener("DOMMouseScroll", MouseWheelHandler, false);